import QuilityAPI from '@/store/API/QuilityAPI'
import Level from '@/store/Models/AgentManagement/Level'

const leadershipLevelsDefaults = [
    "Managing Partner",
    "Senior Partner",
    "Associate Partner",
    "Executive Vice President",
    "Senior Vice President",
    "Managing Vice President",
    "Regional Agency Director",
    "Agency Director",
    "Agency Owner",
    "Key Leader",
    "Team Leader",
    "Sales Representative",
    "SFG",
    "Asurea",
    "Wholesale Agency",
    "Tenant",
    "Quility",
]

const mixin = {
	data () {
		return {
			leadershipLevelsLoading: false,
			leadershipLevelsLoaded: false,
			leadershipLevels: [],
			needsLeadershipLevels: false,
		}
	},
	computed: {
		sortedLeadershipLevels () {
			if (!this.leadershipLevels.length) { return leadershipLevelsDefaults }
			return [...this.leadershipLevels]
				.sort((a, b) => b.LevelRank - a.LevelRank)
				.map(({ LevelName }) => LevelName)
		},
		leadershipOrderRelativeToAO () {
			const AOIndex = this.sortedLeadershipLevels.indexOf('Agency Owner')
			const agentLLIndex = this.sortedLeadershipLevels.indexOf(this.user.Agent.LeadershipLevel)
			if (agentLLIndex === AOIndex) { return 0 }
			return agentLLIndex > AOIndex ? -1 : 1
		},
	},
	watch: {
		needsLeadershipLevels: {
			immediate: true,
			handler () {
				if (!this.needsLeadershipLevels) { return }
				if (this.leadershipLevelsLoaded) { return }
				this.getLevels()
			},
		},
	},
	methods: {
		async getLevels () {
			this.leadershipLevelsLoading = true
			try {
				await QuilityAPI.getAgentManagement_Levels()
				const levels = Level.query().where('LevelType', 'Leadership').get()
				if (levels.length) {
					this.leadershipLevels = levels
				}
				this.leadershipLevelsLoaded = true
			} catch (err) {
				this.showError('Oops! There was a problem loading Leadership Levels.<br>', err.message)
			} finally {
				this.leadershipLevelsLoading = false
			}
        },
	}
}

export default mixin
