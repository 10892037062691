<template>
    <div class="comprehensive-date-filter" :class="isMobileView && 'flex-column'">
		<div class="date-filters">
			<div v-if="showTitle" class="date-filters__title">
				<v-icon small>fa fa-calendar-alt</v-icon>
				<p class="mb-0">{{ $props.title }}</p>
			</div>

			<slot />

			<v-radio-group class="date-filters__periods" v-model="period" :disabled="disabled" hide-details>
				<v-radio
					v-for="({ label, tooltip }) in datePeriods" :key="label"
					:value="label"
					:color="$props.color"
				>
					<template #label>
						<span>{{ label }}</span>
						<v-tooltip v-if="tooltip" top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon :color="$props.color" v-bind="attrs" v-on="on">icon-info-circle-q</v-icon>
							</template>
							<div v-html="tooltip" style="max-width: 25rem" />
						</v-tooltip>
					</template>
				</v-radio>
			</v-radio-group>

			<v-btn-toggle
				v-if="!disableCustom"
				v-model="doShowPicker"
				:disabled="disabled"
				tile borderless
				class="date-filters__custom-range"
			>
				<v-btn :value="true" block text class="date-filters__custom-range-btn">
					<span class="d-inline-flex justify-space-between full-width">
						<span :style="`color:${isCustomPeriod ? $props.color : 'unset'}`">Custom Date Range</span>
						<v-icon>{{ doShowPicker ? 'icon-q-previous' : 'icon-q-next' }}</v-icon>
					</span>
				</v-btn>
			</v-btn-toggle>
		</div>

		<v-divider v-if="doShowPicker" v-bind="dividerProps" />

		<div v-if="doShowPicker" class="date-picker">
			<QComprehensiveDatePeriodPicker
				v-model="filters"
				:color="$props.color"
				:disabled="disabled"
			/>
		</div>
    </div>
</template>

<script>
import moment from 'moment'

import QComprehensiveDatePeriodPicker from '@/components/utils/ComprehensiveFilters/QComprehensiveDatePeriodPicker.vue'
import {
	getBusinessWeekDates,
	getBusinessMonthDates,
	getBusinessQuarterDates,
	getBusinessYearDates,
} from '@/components/utils/BusinessTimeDates'

const defaultPeriods = [
	{ fn: 'lastDays', period: 7 },
	{ fn: 'lastDays', period: 30 },
	{ fn: 'lastDays', period: 60 },
	{ fn: 'lastDays', period: 90 },
	{ fn: 'ytd' },
	{ fn: 'all' },
]

export default {
    components: { QComprehensiveDatePeriodPicker },
    props: {
        value: { type: Object, default: () => ({}) },
		periods: { type: Array, default: () => defaultPeriods },
        title: { type: String, default: 'Time Period' },
        format: { type: String, default: 'YYYY-MM-DD' },
        color: { type: String, default: 'primary' },
        showTitle: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        disableCustom: { type: Boolean, default: false },
    },
    data () {
        return {
            filters: {
                startDate: null, // this.format
                endDate: null, // this.format
            },
            expansionPanel: null,
			doShowPicker: false,
            period: null,
        }
    },
    created () {
        this.resetFilters()
    },
    computed: {
        datePeriods () {
			return this.periods.map(this.getPeriodItem)
        },
		periodsConfig () {
			return {
				today: this.todayConfig,
				currentBusinessWeek: this.currentBusinessWeekConfig,
				currentBusinessMonth: this.currentBusinessMonthConfig,
				currentBusinessQuarter: this.currentBusinessQuarterConfig,
				currentBusinessYear: this.currentBusinessYearConfig,
				lastDays: this.lastDaysConfig,
				lastMonths: this.lastMonthsConfig,
				ytd: this.ytdConfig,
				all: this.allConfig,
			}
		},
		todayDateFormatted () {
			return moment().format(this.format)
		},
		todayConfig () {
			return {
				getDates: () => ({
					startDate: this.todayDateFormatted,
					endDate: this.todayDateFormatted,
				}),
				getLabel: () => `Today`,
				getTooltip: () => null,
			}
		},
		currentBusinessWeekConfig () {
			const { startDate } = getBusinessWeekDates(moment(), this.format)
			return {
				getDates: () => ({
					startDate,
					endDate: this.todayDateFormatted,
				}),
				getLabel: () => `Current Business Week`,
				getTooltip: () => '<strong>Business Week</strong>: A business week starts on <strong>Saturday at 12:01 AM</strong> and ends on <strong>Friday at midnight</strong>. Business week runs from <strong>Saturday through Friday</strong>.',
			}
		},
		currentBusinessMonthConfig () {
			const { startDate } = getBusinessMonthDates(moment(), this.format)
			return {
				getDates: () => ({
					startDate,
					endDate: this.todayDateFormatted,
				}),
				getLabel: () => `Current Business Month`,
				getTooltip: () => '<strong>Business Month</strong>:  The <strong>first day</strong> of the business month is the <strong>Saturday following the last Friday of the prior month</strong>.The <strong>last day</strong> of the business month is always the <strong>last Friday of the calendar month</strong>.',
			}
		},
		currentBusinessQuarterConfig () {
			const { startDate } = getBusinessQuarterDates(moment(), this.format)
			return {
				getDates: () => ({
					startDate,
					endDate: this.todayDateFormatted,
				}),
				getLabel: () => `Current Business Quarter`,
				getTooltip: () => '<strong>Business Quarter</strong>: The <strong>first day</strong> of the business quarter is the <strong>Saturday following the last Friday of the month preceding the quarter start</strong>. The <strong>last day</strong> of the business quarter is the <strong>last Friday of the third calendar month of the quarter</strong>.',
			}
		},
		currentBusinessYearConfig () {
			const { startDate } = getBusinessYearDates(moment(), this.format)
			return {
				getDates: () => ({
					startDate,
					endDate: this.todayDateFormatted,
				}),
				getLabel: () => `Current Business Year`,
				getTooltip: () => '<strong>Business Year</strong>: The <strong>first day</strong> of the business year is the <strong>Saturday following the last Friday of the previous calendar year</strong>. The <strong>last day</strong> of the business year is the <strong>last Friday of December</strong>.',
			}
		},
		lastDaysConfig () {
			return {
				getDates: (days) => ({
					startDate: moment().subtract(days - 1, 'days').format(this.format),
					endDate: this.todayDateFormatted,
				}),
				getLabel: (days) => `Last ${days} Days`,
				getTooltip: () => null,
			}
		},
		lastMonthsConfig () {
			return {
				getDates: (months) => ({
					startDate: moment().subtract(months - 1, 'months').startOf('month').format(this.format),
					endDate: this.todayDateFormatted,
				}),
				getLabel: (months) => `Last ${months} Months`,
				getTooltip: () => null,
			}
		},
		ytdConfig () {
			return {
				getDates: () => ({
					startDate: moment().startOf('year').format(this.format),
					endDate: this.todayDateFormatted,
				}),
				getLabel: () => 'YTD',
				getTooltip: () => null,
			}
		},
		allConfig () {
			return {
				getDates: () => ({
					startDate: null,
					endDate: null,
				}),
				getLabel: () => 'All',
				getTooltip: () => null,
			}
		},
        isCustomPeriod () {
			return !this.datePeriods
				.find(({ value }) => value.startDate === this.filters.startDate && value.endDate === this.filters.endDate)
        },
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		dividerProps () {
			return this.isMobileView
				? { class: 'my-8' }
				: { vertical: true, class: 'mx-8' }
		},
    },
    watch: {
        value: {
            deep: true,
            handler () { this.resetFilters() },
        },
        period () {
            this.calculateFilters()
        },
        filters: {
            deep: true,
            handler () { this.updateFilters() },
        },
    },
    methods: {
		getPeriodItem (item) {
			const { getLabel, getDates, getTooltip } = this.periodsConfig[item.fn]
			const label = item.label
				? typeof item.label === 'function' ? item.label(item) : item.label
				: getLabel(item.period)
			const tooltip = item.tooltip
				? typeof item.tooltip === 'function' ? item.tooltip(item) : item.tooltip
				: getTooltip(item)
			const value = item.dates // { startDate: '...', endDate: '...' } || () => ({ startDate: '...', endDate: '...' })
				? typeof item.dates === 'function' ? item.dates(item) : item.dates
				: getDates(item.period)
			return { ...item, label, tooltip, value }
		},
        updateFilters () {
            this.$emit('input', { ...this.filters })
        },
        resetFilters () {
            this.filters.startDate = this.value.startDate || null
            this.filters.endDate = this.value.endDate || null
            this.updatePeriods()
            this.checkShowPicker()
        },
        calculateFilters () {
            if (!this.period) { return }
			const dates = this.datePeriods.find(({ label }) => label === this.period).value || {}
			this.filters.startDate = dates.startDate
			this.filters.endDate = dates.endDate
        },
        updatePeriods () {
			if (this.isCustomPeriod) {
				this.period = null
				return
			}
			const periods = this.datePeriods
				.filter(({ value }) => value.startDate === this.filters.startDate && value.endDate === this.filters.endDate)
			const alreadySame = periods.some(({ label }) => label === this.period)
			if (!alreadySame) {
				this.period = periods[0].label
			}
        },
        checkShowPicker () {
            if (this.isCustomPeriod) {
                this.doShowPicker = true
            }
        },
    },
}
</script>

<style lang="scss">
.comprehensive-date-filter {
	display: flex;

	.date-filters {
		$padding: 0.75rem;
		&__title {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 0.75rem;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1;
			.v-icon {
				color: inherit;
			}
		}
		&__periods {
			padding-left: $padding;
			padding-right: $padding;
		}
		&__custom-range {
			margin-top: 0.3rem;
			width: 100%;
		}
		&__custom-range-btn {
			padding: 0.75rem calc(#{$padding} + 2px) !important;
			min-height: unset;
			font-size: 1rem;

			.v-btn__content {
				justify-content: flex-start;
				text-transform: none;
				letter-spacing: normal;
			}
		}
	}
}
</style>
