<template>
	<div>
		<div class="q-comprehensive-filter__filters-label">{{ item.label }}</div>
		<QComprehensiveDatePeriodFilter
			v-model="dates"
			:periods="datePeriodsConfig"
			:show-title="false"
			:color="color"
		/>

		<div class="q-comprehensive-filter__filters-actions">
			<v-btn :class="actionBtnClass" outlined :color="color" @click="onCancel">
				Cancel
			</v-btn>
			<v-btn :class="actionBtnClass" :disabled="!valueChanged" :color="color" @click="onSave">
				{{ item.actionLabel || 'Add' }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import QComprehensiveDatePeriodFilter from './QComprehensiveDatePeriodFilter.vue'

export default {
	components: {
		QComprehensiveDatePeriodFilter,
	},
    props: {
		value: { type: Object, required: true },
		item: { type: Object, required: true },
		actionBtnClass: { type: String },
        color: { type: String },
	},
	data () {
		return {
			dates: {
				startDate: null, // YYYY-MM-DD
				endDate: null, // YYYY-MM-DD
			},
			datePeriodsConfig: [
				{ fn: 'today' },
				{ fn: 'currentBusinessWeek' },
				{ fn: 'currentBusinessMonth' },
				{ fn: 'currentBusinessQuarter' },
				{ fn: 'currentBusinessYear' },
				{ fn: 'lastDays', period: 7 },
				{ fn: 'lastDays', period: 30 },
				{ fn: 'lastDays', period: 60 },
				{ fn: 'lastDays', period: 90 },
				{ fn: 'ytd' },
				{ fn: 'all' },
			],
		}
	},
	created () {
		this.updateInnerValues()
	},
    computed: {
		valueChanged () {
			const startDateChanged = this.value.startDate !== this.dates.startDate
			const endDateChanged = this.value.endDate !== this.dates.endDate
			return startDateChanged || endDateChanged
		},
    },
	watch: {
		value () {
			this.updateInnerValues()
		},
	},
	methods: {
		updateInnerValues () {
			this.dates = { ...this.value }
		},
		onCancel () {
			this.$emit('cancel')
		},
		onSave () {
			this.$emit('input', { ...this.dates })
			this.$emit('save')
		},
	},
}
</script>
