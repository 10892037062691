<template>
    <div class="custom-date-period-picker">
		<div class="picker__ranges">
			<v-text-field
				:value="startDateFormatted"
				:color="$props.color"
				clearable
				hide-details
				readonly
				:disabled="$props.disabled"
				placeholder="Start"
				outlined dense
				class="picker__ranges-input"
				@click:clear="clearDate(START_DATE)"
				@click="setActive(START_DATE)"
				@keydown.enter="setActive(START_DATE)"
			/>
			<div>-</div>
			<v-text-field
				:value="endDateFormatted"
				:color="$props.color"
				clearable
				hide-details
				readonly
				:disabled="$props.disabled"
				placeholder="End"
				outlined dense
				class="picker__ranges-input"
				@click:clear="clearDate(END_DATE)"
				@click="setActive(END_DATE)"
				@keydown.enter="setActive(END_DATE)"
			/>
		</div>

		<div class="picker__component">
			<v-date-picker
				:value="pickerDates"
				:picker-date.sync="pickerDate"
				range
				:color="$props.color"
				:style="datePickerStyle"
				:readonly="$props.disabled"
				no-title
				full-width
				@input="update"
			/>
		</div>
    </div>
</template>

<script>
import moment from 'moment'

const START_DATE = 'startDate'
const END_DATE = 'endDate'

export default {
    props: {
        value: { type: Object, required: true }, // { startDate: 'YYYY-MM-DD', endDate: 'YYYY-MM-DD' }
        dateFormat: { type: String, default: 'MM/DD/YYYY' },
        disabled: { type: Boolean, default: false },
        color: { type: String },
    },
    data () {
        return {
			START_DATE,
			END_DATE,
            startDate: this.value.startDate,
            endDate: this.value.endDate,
            startDateFormatted: this.formatValue(this.value.startDate),
            endDateFormatted: this.formatValue(this.value.endDate),
			pickerDate: moment().format('YYYY-MM-DD'),
        }
    },
	computed: {
		pickerDates () {
			return [this.startDate, this.endDate].filter(Boolean)
		},
		datePickerStyle () {
			return {
				'--accent-color': this.color,
			}
		},
	},
    watch: {
		value: {
			deep: true,
			handler () {
				this.startDate = this.value.startDate
				this.endDate = this.value.endDate
				this.startDateFormatted = this.formatValue(this.value.startDate)
				this.endDateFormatted = this.formatValue(this.value.endDate)
			},
		},
    },
    methods: {
        setActive (selected) {
			this.pickerDate = selected === this.START_DATE ? this.startDate : this.endDate
        },
        clearDate (selected) {
			const val = {
				startDate: this.startDate,
				endDate: this.endDate,
			}
			val[selected] = null
            this.$emit('input', val)
        },
        formatValue (value) {
            if (!value) { return null }
            if (!moment(value).isValid()) { return null }
            return moment(value).format(this.dateFormat)
        },
        update (value) {
			const val = {
				startDate: null,
				endDate: null,
			}
			if (value.length === 2) {
				val.startDate = moment(value[0]).isSameOrAfter(moment(value[1]), 'day') ? value[1] : value[0]
				val.endDate = moment(value[0]).isSameOrAfter(moment(value[1]), 'day') ? value[0] : value[1]
			} else {
				const dateRange = moment(value[0]).isSameOrAfter(moment(), 'day') ? this.END_DATE : this.START_DATE
				val[dateRange] = value[0] 
			}
            this.$emit('input', val)
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-date-period-picker {
	max-width: 20rem;

	.picker__ranges {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 0.5rem;

		.picker__ranges-input {
			flex: 1 1 8.5rem;
			max-width: 100%;
			
			::v-deep .v-input__slot {
				padding: 0 8px !important;
				background-color: #F8F8F8;
			}
		}
	}

	.picker__component {
		margin-top: 1.5rem;

		.v-picker {
			box-shadow: none;

			::v-deep .v-date-picker-header {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}

			::v-deep .v-date-picker-table {
				padding: 0;

				&.v-date-picker-table--date {
					.v-btn {
						border-radius: 50%;
					}
				}

				&.v-date-picker-table--month {
					.v-btn {
						border-radius: 6px;
					}
				}

				&.v-date-picker-table--date,
				&.v-date-picker-table--month {
					td:has(.v-btn.v-btn--active) {
						.v-btn.v-btn--active {
							background-color: #fff !important;
							color: var(--accent-color);
							font-weight: 600;
						}
						.v-btn.v-btn--active.v-date-picker-table__current {
							border: solid 2px var(--accent-color) !important;
						}
					}
				}
			}
		}
	}
}
</style>
