import moment from 'moment'
import { weekdiff as weekDiff } from './BusinessTimeDateDiff'

export const getBusinessWeekDates = (date = moment(), format = 'YYYY-MM-DD') => {
	const utcDate = moment.utc(date)
	const startDiff = weekDiff[utcDate.format('dddd')].saturday
	const startDate = utcDate.clone().subtract(startDiff, 'days')
	const endDate = startDate.clone().add(6, 'days')
	return {
		startDate: startDate.format(format),
		endDate: endDate.format(format),
	}
}

export const getBusinessMonthDates = (date = moment(), format = 'YYYY-MM-DD') => {
	const utcDate = moment.utc(date)
	const startOfMonth = utcDate.clone().startOf('month')
	const endOfMonth = utcDate.clone().endOf('month')
	const startDiff = weekDiff[startOfMonth.format('dddd')].saturday
	const endDiff = weekDiff[endOfMonth.format('dddd')].friday
	return {
		startDate: startOfMonth.subtract(startDiff, 'days').format(format),
		endDate: endOfMonth.subtract(endDiff, 'days').format(format),
	}
}

export const getBusinessQuarterDates = (date = moment(), format = 'YYYY-MM-DD') => {
	const utcDate = moment.utc(date)
	const startOfQuarter = utcDate.clone().startOf('quarter')
	const endOfQuarter = utcDate.clone().endOf('quarter')
	const startDiff = weekDiff[startOfQuarter.format('dddd')].saturday
	const endDiff = weekDiff[endOfQuarter.format('dddd')].friday
	return {
		startDate: startOfQuarter.subtract(startDiff, 'days').format(format),
		endDate: endOfQuarter.subtract(endDiff, 'days').format(format),
	}
}

export const getBusinessYearDates = (date = moment(), format = 'YYYY-MM-DD') => {
	const utcDate = moment.utc(date)
	const startOfYear = utcDate.clone().startOf('year')
	const endOfYear = utcDate.clone().endOf('year')
	const startDiff = weekDiff[startOfYear.format('dddd')].saturday
	const endDiff = weekDiff[endOfYear.format('dddd')].friday
	return {
		startDate: startOfYear.subtract(startDiff, 'days').format(format),
		endDate: endOfYear.subtract(endDiff, 'days').format(format),
	}
}
